<template>
  <div >
    <v-card-text >

      <v-row justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            ref="CorreoElectronico"
            v-model="email"
            counter
            maxlength="80"
            prepend-inner-icon="mdi-email"
            required
          >
            <template #label>
              <div class="text-center"> {{ emailType === 'EmailFE' ? 'Correo Electrónico Facturas Electrónicas' : 'Correo Electrónico Delivery' }} </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end">

        <v-btn
            text
            style="background-color:rgb(204 33 40);color:white !important"
            @click="updateEmail"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
      <v-alert
          v-show="successAlert"
          type="success"
          text
      >
        {{ msgSuccessAlert }}
      </v-alert>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'campoClientEmailFacturacion',
  props: ['token_enterprise', 'token_password', 'email', 'emailType'],

  data () {
    return {
      msgSuccessAlert: 'Datos actualizados exitosamente',
      successAlert: false,
    }
  },

  computed: {
    ...mapState('clients', [
      'updateEmailFEResponse',
      'updateEmailDeliveryResponse',
    ]),
  },

  watch:
      {
    updateEmailFEResponse(newValue) {
      if (newValue && this.emailType === 'EmailFE') {
        this.generateAlertSuccess('Facturas Electrónicas')
        this.$store.dispatch('clients/setUpdateResponseEmailFE', false)
        this.$emit('email-updated')
      }
    },
    updateEmailDeliveryResponse(newValue) {
      if (newValue && this.emailType === 'EmailDelivery') {
        this.generateAlertSuccess('Delivery')
        this.$store.dispatch('clients/setUpdateResponseEmailDelivery', false)
        this.$emit('email-updated')
      }
    },
  },
  methods: {
    generateAlertSuccess(emailCategory = '') {
      this.msgSuccessAlert = `Correo Electrónico ${emailCategory} actualizado exitosamente`;
      this.successAlert = true;
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    async updateEmail () {
      const emailData = {
        email: this.email,
        token_password: this.token_password,
        token_enterprise: this.token_enterprise,
        user: JSON.parse(atob(localStorage.getItem('uid'))).user,
      };

      if (this.emailType === 'EmailFE') {
        await this.$store.dispatch('clients/updateEmailFE', emailData);
      } else if (this.emailType === 'EmailDelivery') {
        await this.$store.dispatch('clients/updateEmailDelivery', emailData);
      }
    },
  },
}
</script>

<style scoped>

</style>
